.container {
  width: auto;
  display: flex;
  justify-content: space-evenly;
  padding: 0 26px 5px;
}

.block {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 4px;
    width: 33.3%;
    font-weight: 600;
    color: var(--waterBlue);
    text-align: center;
}

.borders {
  border-right: 1px solid #d8d8d8;
  border-left: 1px solid #d8d8d8;
}

.image {
  margin-right: 5px;
}
